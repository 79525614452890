* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

html,
body {
    overflow: hidden;
    background: black;
    /*background: radial-gradient(circle, rgba(175,174,238,1) 0%, rgba(233,148,172,1) 100%);*/
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    background: aliceblue;
}
div#ball{
    transition: left 0.5s, top 0.5s;
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(233,148,172,1) 0%, rgba(175,174,238,1) 100%);
}